var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-card',{attrs:{"id":"page--advanced-search","no-body":""}},[_c('div',[_c('div',{staticClass:"m-2 d-flex justify-content-between table-actions"},[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"mr-1"},[_c('b-button',{class:{ 'icon-disabled': !_vm.canCreate },attrs:{"id":"create-button","variant":"primary"},on:{"click":function($event){_vm.canCreate && _vm.create()}}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"PlusIcon"}}),_c('span',{staticClass:"align-middle"},[_vm._v(_vm._s(_vm.$t('Create New')))])],1),_c('b-tooltip',{attrs:{"target":"create-button"}},[_vm._v(" "+_vm._s(_vm.canCreate ? _vm.$t('Create') : _vm.$t('advanced-search-create-not-allowed-text'))+" ")])],1),(_vm.exports.length)?_c('ImexAvailableExportsDropdown',{attrs:{"permitted":true,"list":_vm.exports,"get-export-file":_vm.getExportFile}}):_vm._e()],1)]),_c('div',{staticClass:"m-2"},[_c('b-table',{ref:"refTable",staticClass:"table-responsive",attrs:{"busy":_vm.loading,"items":_vm.items,"responsive":"","fields":_vm.tableColumns,"primary-key":"id","show-empty":"","empty-text":_vm.$t('No matching records found'),"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc},on:{"sort-changed":_vm.handleSortChange},scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center my-2"},[_c('b-spinner',{staticClass:"align-middle"})],1)]},proxy:true},{key:"head()",fn:function({ label }){return [_c('span',{staticStyle:{"white-space":"nowrap"}},[_vm._v(_vm._s(_vm.$t(label)))])]}},{key:"cell(name)",fn:function({ item }){return [_c('prozess-resource-name',{staticClass:"text-nowrap d-inline-block",attrs:{"id":`view-row-${item.uuidKey}-link`,"has-permission":_vm.hasPermissionTo('Read', item),"to":{
              name: 'advanced-search-view.config.view',
              params: {
                id: item.uuidKey,
                action: 'run',
                queryType: _vm.queryType(item),
              },
            },"title":item.name}}),(!_vm.hasPermissionTo('Read', item))?_c('b-tooltip',{attrs:{"placement":"left","title":_vm.actionTooltip(item, 'Read'),"target":`view-row-${item.uuidKey}-link`}}):_vm._e()]}},{key:"cell(type)",fn:function({ item }){return [_c('b-badge',{staticClass:"tw-px-4",attrs:{"pill":"","variant":"primary"}},[_c('feather-icon',{attrs:{"icon":item.isGlobal ? 'GlobeIcon' : 'UserIcon'}})],1)]}},{key:"cell(tables)",fn:function({ item }){return _vm._l((_vm.relatedEntities(item)),function(table,i){return _c('b-badge',{key:i,staticClass:"tw-mr-2 tw-px-4",attrs:{"pill":"","variant":"secondary"}},[_vm._v(" "+_vm._s(table)+" ")])})}},{key:"cell(fields)",fn:function({ item }){return [_c('b-badge',{staticClass:"tw-px-4 tw-min-w-16",attrs:{"pill":"","variant":"secondary"}},[_vm._v(" "+_vm._s(item.numberOfFields)+" ")])]}},{key:"cell(filters)",fn:function({ item }){return [_c('b-badge',{staticClass:"tw-px-4 tw-min-w-16",attrs:{"pill":"","variant":"secondary"}},[_vm._v(" "+_vm._s(item.numberOfFilters)+" ")])]}},{key:"cell(actions)",fn:function({ item }){return [_c('div',{staticClass:"text-nowrap"},[_c('feather-icon',{staticClass:"mx-1 cursor-pointer",class:{
                'icon-disabled': !_vm.hasPermissionTo('Read', item),
              },attrs:{"id":`view-row-${item.uuidKey}-icon`,"icon":"EyeIcon","size":"16"},on:{"click":function($event){return _vm.handleViewClick(item)}}}),_c('b-tooltip',{attrs:{"placement":"left","title":_vm.actionTooltip(item, 'View', 'Read'),"target":`view-row-${item.uuidKey}-icon`}}),_c('feather-icon',{staticClass:"mx-1 cursor-pointer",class:{
                'icon-disabled': !_vm.hasPermissionTo('Update', item),
              },attrs:{"id":`edit-row-${item.uuidKey}-icon`,"icon":"EditIcon","size":"16"},on:{"click":function($event){return _vm.edit(item)}}}),_c('b-tooltip',{attrs:{"placement":"left","title":_vm.actionTooltip(item, 'Edit', 'Update'),"target":`edit-row-${item.uuidKey}-icon`}}),_c('feather-icon',{staticClass:"mx-1 cursor-pointer",class:{
                'icon-disabled': !_vm.hasPermissionTo('Delete', item),
              },attrs:{"id":`delete-row-${item.uuidKey}-icon`,"icon":"TrashIcon","size":"16"},on:{"click":function($event){return _vm.confirmRemoval(item)}}}),_c('b-tooltip',{attrs:{"placement":"left","title":_vm.actionTooltip(item, 'Delete'),"target":`delete-row-${item.uuidKey}-icon`}})],1)]}}])})],1),(!_vm.loading)?_c('prozess-pagination',{staticClass:"mx-2 mb-2",class:{ 'd-none': !_vm.total },attrs:{"meta":_vm.dataMeta,"page":_vm.currentPage,"total":_vm.total,"size":_vm.perPage},on:{"change":_vm.handleCurrentPage}}):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }