<template>
  <b-card
    id="page--advanced-search"
    no-body
  >
    <div>
      <div class="m-2 d-flex justify-content-between table-actions">
        <div class="d-flex">
          <div class="mr-1">
            <b-button
              id="create-button"
              variant="primary"
              :class="{ 'icon-disabled': !canCreate }"
              @click="canCreate && create()"
            >
              <feather-icon
                icon="PlusIcon"
                class="mr-50"
              />
              <span class="align-middle">{{ $t('Create New') }}</span>
            </b-button>
            <b-tooltip target="create-button">
              {{ canCreate ? $t('Create') : $t('advanced-search-create-not-allowed-text') }}
            </b-tooltip>
          </div>
          <ImexAvailableExportsDropdown
            v-if="exports.length"
            :permitted="true"
            :list="exports"
            :get-export-file="getExportFile"
          />
        </div>
      </div>
      <div class="m-2">
        <b-table
          ref="refTable"
          :busy="loading"
          :items="items"
          responsive
          :fields="tableColumns"
          primary-key="id"
          show-empty
          :empty-text="$t('No matching records found')"
          class="table-responsive"
          :sort-by="sortBy"
          :sort-desc="sortDesc"
          @sort-changed="handleSortChange"
        >
          <template #table-busy>
            <div class="text-center my-2">
              <b-spinner class="align-middle" />
            </div>
          </template>

          <template #head()="{ label }">
            <span style="white-space: nowrap">{{ $t(label) }}</span>
          </template>

          <template #cell(name)="{ item }">
            <prozess-resource-name
              :id="`view-row-${item.uuidKey}-link`"
              class="text-nowrap d-inline-block"
              :has-permission="hasPermissionTo('Read', item)"
              :to="{
                name: 'advanced-search-view.config.view',
                params: {
                  id: item.uuidKey,
                  action: 'run',
                  queryType: queryType(item),
                },
              }"
              :title="item.name"
            />
            <b-tooltip
              v-if="!hasPermissionTo('Read', item)"
              placement="left"
              :title="actionTooltip(item, 'Read')"
              :target="`view-row-${item.uuidKey}-link`"
            />
          </template>
          <template #cell(type)="{ item }">
            <b-badge
              pill
              variant="primary"
              class="tw-px-4"
            >
              <feather-icon :icon="item.isGlobal ? 'GlobeIcon' : 'UserIcon'" />
            </b-badge>
          </template>
          <template #cell(tables)="{ item }">
            <b-badge
              v-for="(table, i) in relatedEntities(item)"
              :key="i"
              pill
              variant="secondary"
              class="tw-mr-2 tw-px-4"
            >
              {{ table }}
            </b-badge>
          </template>
          <template #cell(fields)="{ item }">
            <b-badge
              pill
              variant="secondary"
              class="tw-px-4 tw-min-w-16"
            >
              {{ item.numberOfFields }}
            </b-badge>
          </template>
          <template #cell(filters)="{ item }">
            <b-badge
              pill
              variant="secondary"
              class="tw-px-4 tw-min-w-16"
            >
              {{ item.numberOfFilters }}
            </b-badge>
          </template>

          <!-- START: Actions Column -->
          <template #cell(actions)="{ item }">
            <div class="text-nowrap">
              <feather-icon
                :id="`view-row-${item.uuidKey}-icon`"
                icon="EyeIcon"
                size="16"
                class="mx-1 cursor-pointer"
                :class="{
                  'icon-disabled': !hasPermissionTo('Read', item),
                }"
                @click="handleViewClick(item)"
              />
              <b-tooltip
                placement="left"
                :title="actionTooltip(item, 'View', 'Read')"
                :target="`view-row-${item.uuidKey}-icon`"
              />
              <feather-icon
                :id="`edit-row-${item.uuidKey}-icon`"
                icon="EditIcon"
                size="16"
                class="mx-1 cursor-pointer"
                :class="{
                  'icon-disabled': !hasPermissionTo('Update', item),
                }"
                @click="edit(item)"
              />
              <b-tooltip
                placement="left"
                :title="actionTooltip(item, 'Edit', 'Update')"
                :target="`edit-row-${item.uuidKey}-icon`"
              />
              <feather-icon
                :id="`delete-row-${item.uuidKey}-icon`"
                icon="TrashIcon"
                size="16"
                class="mx-1 cursor-pointer"
                :class="{
                  'icon-disabled': !hasPermissionTo('Delete', item),
                }"
                @click="confirmRemoval(item)"
              />
              <b-tooltip
                placement="left"
                :title="actionTooltip(item, 'Delete')"
                :target="`delete-row-${item.uuidKey}-icon`"
              />
            </div>
          </template>
          <!-- END: Actions Column -->
        </b-table>
      </div>
      <prozess-pagination
        v-if="!loading"
        class="mx-2 mb-2"
        :class="{ 'd-none': !total }"
        :meta="dataMeta"
        :page="currentPage"
        :total="total"
        :size="perPage"
        @change="handleCurrentPage"
      />
    </div>
  </b-card>
</template>

<script>
import { ImexStatus } from '@/constants/imex'
import { QueryType } from '@/constants/advancedSearch'
import { swalConfirmDeleteOption } from '@/helpers/app'
import advancedSearchService from '@/services/advancedSearch'
import listMixinFactory from '@/mixins/list'
import ImexAvailableExportsDropdown from '@/views/shared/Imex/ImexAvailableExportsDropdown.vue'

export default {
  components: {
    ImexAvailableExportsDropdown,
  },

  mixins: [
    listMixinFactory({
      routeName: 'advanced-search',
      searchField: 'name',
      service: advancedSearchService,

      tableColumns: [
        { key: 'name', label: 'Name', sortable: true },
        { key: 'type', label: 'Type', class: 'disabled' },
        { key: 'tables', label: 'Tables', class: 'disabled' },
        { key: 'fields', label: 'Fields', class: 'disabled' },
        { key: 'filters', label: 'Filters', class: 'disabled' },
        { key: 'actions', class: 'text-center disabled' },
      ],

      sort: {
        name: 'name',
      },
    }),
  ],

  data() {
    return {
      QueryType,
      resourceId: null,
      showForm: false,
      exports: [],
    }
  },

  computed: {
    canCreate() {
      return this.$can('Create', 'Global_Query') || this.$can('Create', 'Personal_Query')
    },
  },

  mounted() {
    this.getRecentExports()
  },

  methods: {
    afterFetchCallback(res) {
      this.total = res.data.allItemsCount
      this.items = res.data.pageItems
    },

    confirmRemoval(item) {
      if (!this.hasPermissionTo('Delete', item)) {
        return
      }

      this.$swal(swalConfirmDeleteOption(v => this.$t(v))).then(async result => {
        if (result.value) {
          await this.$async(advancedSearchService.deleteByType(this.queryType(item), item.uuidKey))
          this.fetchList()
        }
      })
    },

    queryType({ isGlobal }) {
      return isGlobal ? QueryType.GLOBAL : QueryType.PERSONAL
    },

    queryName({ isGlobal }) {
      return isGlobal ? 'Global_Query' : 'Personal_Query'
    },

    // `action` can only be "Read", "Edit", "Delete"
    hasPermissionTo(action, item) {
      return this.$can(action, this.queryName(item))
    },

    /**
     * `actionLabel` can only be "Read", "Edit", "Delete"
     * `permissionName` can only be "Read", "Update", "Delete"
     * `actionKey` can only be "view", "edit", "delete"
     */
    actionTooltip(item, actionLabel, permissionName = null) {
      const searchType = this.$case.kebab(this.queryName(item))
      const actionKey = actionLabel.toLowerCase()

      return this.hasPermissionTo(permissionName || actionLabel, item) ? this.$t(actionLabel) : this.$t(`no-${actionKey}-${searchType}-permission-text`)
    },

    create() {
      this.$router.push({
        name: 'advanced-search.create',
      })
    },

    edit(item) {
      if (!this.hasPermissionTo('Update', item)) {
        return false
      }

      this.$router.push({
        name: 'advanced-search-view.edit',
        params: {
          id: item.uuidKey,
          queryType: this.queryType(item),
        },
      })
    },

    async getAllMethod(query) {
      return advancedSearchService.getList(query)
    },

    getExportFile: advancedSearchService.getOneExportFile,

    async getRecentExports() {
      const { response } = await this.$async(advancedSearchService.getRecentExports({ status: ImexStatus.COMPLETED }))

      this.exports = response?.data || []
    },

    handleViewClick(item) {
      this.$router.push({
        name: 'advanced-search-view.config.view',
        params: {
          id: item.uuidKey,
          action: 'view',
          queryType: this.queryType(item),
        },
      })
    },

    relatedEntities(item) {
      return item.entities.filter((_, i) => i < 3).map(({ tableKey }, i) => (i === 2 ? `+ ${item.entities.length - 2}` : this.$t(this.$case.title(tableKey))))
    },
  },
}
</script>
