<template>
  <div class="imex__available-exports-dropdown mr-1">
    <!-- <b-dropdown
      :id="id"
      :disabled="!permitted"
      :text="$t('Recently exported data')"
      variant="outline-primary"
      @shown="$emit('open')"
    >
      <b-dropdown-item
        v-for="(item, i) in list"
        :key="i"
        @click="download(item)"
      >
        <feather-icon
          icon="FileTextIcon"
          class="mr-1"
        />
        {{ item.fileName }}
      </b-dropdown-item>
    </b-dropdown> -->

    <div class="d-flex">
      <p class="mb-0">{{ $t('Recently exported data') }}</p>
      <b-spinner v-if="onDownload" small class="align-middle" style="margin-left: 6px;margin-top: 2px;"/>
    </div>
    <ProzessSelect
       v-if="$emit('open')"
       v-model="selectedDownload"
       :clearable="false"
       :options="transformList(list)"
       :disabled="isDisabled()"
       style="width: 220px;"
    />
    <b-tooltip
      v-if="!permitted"
      :target="id"
    >
      {{ $t('no-view-exports-imex-permision') }}
    </b-tooltip>
  </div>
</template>

<script>
import { downloadFromURL } from '@/helpers/app'
import imexService from '@/services/imex'
import ProzessSelect from '@/@core/components/ProzessSelect.vue'

export default {

  components: {
    ProzessSelect,
  },
  props: {
    list: {
      type: Array,
      required: true,
    },

    id: {
      type: String,
      default: 'view-recently-exported-files',
    },

    permitted: {
      type: Boolean,
      default: true,
    },

    getExportFile: {
      type: Function,
      default: null,
    },
  },

  data() {
    return {
        selectedDownload: {},
        onDownload: false,
    }
  },

  watch: {
    selectedDownload: {
      async handler() {
        if (this.selectedDownload != null) {
          this.onDownload = true
          await this.download(this.selectedDownload.obj)
          this.selectedDownload = null
          this.onDownload = false
        }
      },
    },
  },
  methods: {
    isDisabled() {
      return !this.permitted || this.onDownload
    },
    async download(file) {
      const { response } = await this.$async(this.getExportFile ? this.getExportFile({ fileId: file.id }) : imexService.getOneExportFile(file.id))

      downloadFromURL(response?.data?.url, file.fileName)
    },
    transformList(list) {
      const options = []
      list.forEach(ii => {
        options.push({ key: ii.id, label: ii.fileName, obj: ii })
      })
      return options
    },
  },
}
</script>
<style lang="scss">
.imex__available-exports-dropdown {
  ul.dropdown-menu {
    min-width: 100%;
  }
}
</style>
