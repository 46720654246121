import API from '@axios'
import { apiHelper } from '@/helpers/apiHelper'
import { stringify } from 'query-string'

const API_URL = '/api/search/advance'

export default {
  ...apiHelper(API_URL),

  /**
   * `type` must either be QueryType.PERSONAL or QueryType.GLOBAL
   */
  getOneByType(type, id, query = {}) {
    return API.get(`${API_URL}/${type}/${id}?${stringify(query)}`)
  },

  getList(query = { size: 25 }) {
    return API.get(`${API_URL}/list?${stringify(query)}`)
  },

  getEntities() {
    return API.get(`${API_URL}/entities`)
  },

  runUnsavedQuery(payload, query = { size: 25 }) {
    return API.post(`${API_URL}/run?${stringify(query)}`, payload)
  },

  runUnsavedQueryAdvanced(payload) {
    return API.post(`${API_URL}/run-advanced`, payload)
  },

  runSavedQuery(type, id, { orders = [] }, query = { size: 25 }) {
    return API.post(`${API_URL}/${type}/${id}/run?${stringify(query)}`, orders)
  },

  saveByType(type, payload) {
    return API.post(`${API_URL}/${type}`, payload)
  },

  updateByType(type, id, payload) {
    return API.put(`${API_URL}/${type}/${id}`, payload)
  },

  initiateExport(payload) {
    return API.put(`${API_URL}/initiate_export`, payload)
  },

  getRecentExports: (query = {}) => API.get(`${API_URL}/bulk_data/recent?${stringify(query)}`),

  getOneExportFile: ({ fileId }) => API.get(`${API_URL}/bulk_data/download/${fileId}`),

  deleteByType(type, id) {
    return API.delete(`${API_URL}/${type}/${id}`)
  },
}
