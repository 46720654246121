export const ImexStatus = {
  COMPLETED: 'COMPLETED',
  FAILED: 'FAILED',
  EXECUTING: 'EXECUTING',
  UNKNOWN: 'UNKNOWN',
  NOOP: 'NOOP',
  STOPPED: 'STOPPED',
}

export const ImexStatusText = {
  COMPLETED: 'Import success',
  FAILED: 'Import failed',
  EXECUTING: 'Importing...',
  UNKNOWN: 'Unknown',
  NOOP: 'No Operation',
  STOPPED: 'Import stopped',
}

export const ImexStatusIcon = {
  COMPLETED: 'CheckCircleIcon',
  FAILED: 'AlertTriangleIcon',
  EXECUTING: 'RefreshCwIcon',
  UNKNOWN: 'HelpCircleIcon',
  NOOP: 'CircleIcon',
  STOPPED: 'StopCircleIcon',
}

export const ImexStatusIconColor = {
  COMPLETED: '#479B0A',
  FAILED: '#FA8E00',
  EXECUTING: 'white',
  UNKNOWN: '#FA8E00',
  NOOP: '#FA8E00',
  STOPPED: '#DE1927',
}

export const ImexStatusIconClass = {
  EXECUTING: 'pz-rotate-animation',
}
